@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital@0;1&family=Unbounded:wght@500&display=swap');


.cards-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top:80px ;
  
    
   

}
.main-background{
    z-index: -1;
    position: absolute;
    width: 100%;
    height:430px ;
  
    /* background-color:rgba(252, 201, 210, 0.651) */
    
}
.main-background img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.cards{
    width: 100%;
    max-width: 1200px;
    display: flex;
   align-items: center;
    flex-direction: column;
    
}
.cards p{
    font-weight: 400;
    font-size: 45px;
    line-height: 55px;
    text-align: center;
    font-family: "Unbounded", sans-serif;
    width: 80%;
    padding-top: 30px;
}
.cards p strong{
    color: var(--main-color);
    font-weight: 400;
}
.con-cards{
    width: 95%;
    display: flex;
    justify-content: space-between;
}
.cardss{
    width:32% ;
    height: auto;
    border-radius: 22px;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 58px;
   padding: 15px 0 40px 0;
   min-width:300px ;
   

}
.card-img{
    width: 65px;
    height: 65px;
    
}
.card-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}
.cardss p{
    font-weight: 400;
    font-size: 27px;
    line-height: 42px;
    text-align: center;
    color: white;
    font-family: "Plus Jakarta Sans", sans-serif;
    height: 50%;
    padding-top: 10px;

}
@media screen and (max-width:948px) {
    .con-cards{
        width: 100%;
        justify-content: center;
   align-items: center;
    }
    
}
@media screen and (max-width:1200px) {
    .cards p{
        width: 90%;
    }
    
}
@media screen and (max-width:950px) {
   .con-cards{
    flex-direction: column;
   }
    
}
@media screen and (max-width:850px) {
    .cards p{
       font-size: 35px;
    }
    
}
@media screen and (max-width:600px) {
    .cards p{
       font-size: 25px;
    }
    
}
@media screen and (max-width:500px) {
   .cards-main{
    margin-top: 10px;
   }
   .cards p{
    font-size: 18px;
    line-height: 30px;
   }
}

