@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital@0;1&family=Unbounded:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,500;1,500&family=Unbounded:wght@500&display=swap');
.header-main{
    width: 100%;
    height: 850px;
    display: flex;
    justify-content: center;
}
.header {
    width: 100%;
    max-width: 1250px;
    display: flex;
    /* overflow: hidden; */
}
.header-left{
    width: 48%;
    height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
/* .header-right{
    width: 50%;
    height: 850px;
    display: flex;
    align-items: end;
}
.header-right img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    
} */
.header-left .header-title{
    font-family: "Plus Jakarta Sans", sans-serif;
     font-style: normal;
     font-weight: 700;
     font-size: 24px;
     line-height: 57px;
     color: var(--main-color);
}
.header-left .header-text{
    font-family: "Unbounded", sans-serif;
    font-weight: 500;
    font-size: 48px;
    line-height: 57px;

}
.header-left .header-text strong{
    color: var(--main-color);
    font-weight: 500;
}
.header-left .header-button{
    font-family: "Unbounded", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 800;
    color: var(--main-color);
    width: 185px;
    height: 50px;
    border: .85px solid var(--main-color);
    box-shadow: 3px 3px 0 0 white, 3px 3px 0 .85px var(--main-color);
    background-color: white;
    /* margin-top: 30px; */
    margin-bottom: 30px;
    display: inline-block;
    transition: color .5s;
    position: relative;
    --color: rgba(136, 60, 136, 1);
    overflow: hidden;
    z-index: 1;
}
.header-left .header-button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 60px;
    width: 160%;
    border-radius: 0%;
   
}

.header-left .header-button:hover {
    color: #fff;
}

.header-left .header-button:before {
    top: 0;
    left: 100%; /* Changed from 100% */
    transition: all .7s;
}

.header-left .header-button:hover:before {
    top: 0;
    left: -60%; /* Changed from -30px */
}

.header-left .header-button:active:before {
    background: var(--main-color);
    transition: background-color 0s;
}

.header-cont{
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header-left .header-2text{
    font-family: "unbounded", sans-serif;
    font-weight: 400;
    font-size: 27px;
    width: 80%;
    font-style: normal;
    line-height: 30px;
    color: var(--main-color);
}
.profile-images {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px; /* Adjust as needed */
}
.ratings{
    width: 90%;
    display: flex;
    align-items: end;
    justify-content: space-between;  
    box-sizing: border-box;
}
.ratings p{
    font-family: "unbounded", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    width: 38%;
    height: 90px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.profile-image {
    width: 90px;
    height: 90px; 
    border-radius: 50%;
    border: none;
    background-color: transparent; /* Default background color */
    position: relative;
    margin-left: -30px; 
    background-size: contain;
}


@media screen and (max-width: 1300px) {
    .header {
        padding: 30px; 
    }
    .header-left .header-text{
        font-size: 40px;
    }
    .header-left{
        height: 80vh;
    }

}
@media screen and (max-width:1200px) {
    .profile-image{
        width: 70px;
        height: 70px;
        margin-left: -20px;
    }
    .header-left .header-2text{
        font-size: 24px;
    }
    .ratings p{
        font-size: 15px;
        line-height: 20px;
        padding-top: 20px;
    }
    .ratings{
        align-items: center;
    }
    
 
    
}
@media screen and (max-width:1100px) {
    .profile-image{
        width: 60px;
        height: 60px;
    }
    .ratings p{
        font-size: 14px;
    }
    .header-left .header-text{
        font-size: 35px;
    }
    .header-left .header-title{
        font-size: 20px;
    }
    .header-left .header-2text{
        font-size: 22px;
    }
    .header-left{
        height: 700px;
    }
    .header-main{
        height: 750px ;
    }
    

    
}
@media screen and (max-width:1340px) {
    .ratings{
        padding-left: 30px;
    }
    
}
@media screen and (max-width:900px) {
   .header{
    width: 85%;

   }
   .header-left{
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 560px;
   }
   .header-cont{
    width: 100%;
    align-items: center;
    text-align: center;
   }
   .header-main{
    height:560px;
   }
   .header-left .header-text{
    font-size: 22px;
   }
   .header-left .header-2text{
    font-size: 18px;
   }
   
    
}
@media screen and (max-width:650px) {
    .ratings{
        padding-top: 50px;
    }
     .ratings p{}
    
}
@media screen and (max-width:550px) {
   .header-left .header-text{
    font-size: 18px;
    line-height: 30px;
   }
   .header-left .header-2text{
    font-size: 15px;
    line-height: 30px;
   }
   .header-left .header-title{
    line-height: 30px;
   }
   .header-main{
    height: auto;
   
   }
   .header-left{
    height: auto;
   }
   .ratings{
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
   .ratings p{
    width: 60%;
    text-align: center;
   }
   .header-left .header-button{
    height: 40px;
   }
   .ratings{
   height: 150px;
   padding-top: 0;
  width: 100%;
  padding-left: 0;
   }
   .ratings p{
    height: auto;
    width: 80%;
   }
   .ratings .profile-image{
   width: 50px;
   height: 50px;
   }
    
}

