.dilog-inputs .terms-check p {
  font-size: 15px;
}

.location-main {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
}
.location-text {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 21px;
  color: rgba(30, 30, 30, 1);
  font-family: "Plus Jakarta Sans", sans-serif;
}
.location-title {
  font-weight: 400;
  font-size: 45px;
  text-align: center;
  line-height: 55px;
  font-family: "Unbounded", sans-serif;
  padding-bottom: 20px;
}
.location {
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
}
.location-comp {
  display: flex;
  width: 100%;
  padding-top: 40px;
}
.location-left {
  width: 50%;
  /* height: 540px; */
}
.location-right {
  width: 50%;
  /* height: 500px; */
  display: flex;
  justify-content: center;
}
.location-cont {
  width: 95%;
  height: 610px;
}
.location-search {
  width: 100%;
  display: flex;
  margin-bottom: 35px;
}
.location-search input {
  font-family: "Plus Jakarta Sans", sans-serif;
  height: 67px;
  width: 85%;
  outline: none;
  background-color: rgba(255, 241, 255, 1);
  border: none;
  border-radius: 4px;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
}
.location-searchinput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.location-search input::placeholder {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgba(30, 30, 30, 1);
}
.location-search button {
  width: 67px;
  height: 67px;
  background-color: var(--main-color);
  border: none;
  border-radius: 4px;
}
.location-search button img {
  width: 40px;
}
.upper-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  box-sizing: border-box;
}
.location-icons {
  display: flex;
  align-items: center;
}
.location-icons button {
  background-color: var(--main-color);
  border: none;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upper-box p {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: rgba(30, 30, 30, 1);
  font-family: "Plus Jakarta Sans", sans-serif;
}
.location-icons :nth-child(1) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.location-icons .button2 {
  width: 145px;
  height: 38px;
  border-radius: 19px;
  margin-left: 10px;
}
.location-icons button img {
  width: 25px !important;
}
.location-box {
  width: 100%;
  display: flex;
  min-height: 210px !important;
  height: auto;
  flex-direction: column;
  align-items: center;
}
.location-padding {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 20px;
  margin-bottom: 14px;
}
.location-info {
  display: flex;
  align-items: center;
}
.location-info img {
  width: 15px;
  margin-right: 20px;
}
.location-info p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: rgba(30, 30, 30, 1);
  margin-bottom: 0;
  line-break: anywhere;
}
.lower-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 10px;
  justify-content: space-evenly;
}
@media screen and (max-width: 1300px) {
  .location {
    padding: 0px 20px 0px 20px;
  }
}
@media screen and (max-width: 1000px) {
  .location-comp {
    flex-direction: column;
    align-items: center;
  }
  .location-left {
    width: 90%;
    height: 500px;
    margin-bottom: 50px;
  }
  .location-right {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .location-title {
    font-size: 35px;
  }
}
a {
  text-decoration: none;
  color: black;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: rgba(30, 30, 30, 1);
}
@media screen and (max-width: 500px) {
  .location-title {
    font-size: 20px;
    line-height: 30px;
  }
}
.dilog-inputs input {
  margin-bottom: 20px;
  height: 40px;
  border: 1px solid rgba(128, 128, 128, 0.445);
  border-radius: 10px;
  padding-left: 5px;
}

.dilog-inputs textarea {
  border: 1px solid rgba(128, 128, 128, 0.445);
  border-radius: 10px;
}
.dilog-recepathch {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
