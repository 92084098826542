.home-main {
  width: 100%;
  height: 950px;
  position: absolute;
  z-index: -1;
  /* max-width: 1440px; */
  overflow: hidden;
  display: flex;
  justify-content: end;
  align-items: end;
}
.home .appbar-main {
  position: fixed;
  top: 0;
  background-image: linear-gradient(
    to right,
    rgb(242, 244, 246) 5%,
    rgb(232, 236, 239) 20%
  );
  z-index: 5;
  height: auto;
  padding: 0 !important;
}

.home-main :nth-child(1) {
  width: 40%;
  height: 90%;
  object-fit: contain;
}
.home-main :nth-child(2) {
  width: 175px;
  height: 397px;
  object-fit: cover;
  margin-bottom: 25%;
}
@media screen and (max-width: 1250px) {
  .home-main :nth-child(2) {
    width: 15%;
  }
}
@media screen and (max-width: 1110px) {
  .home-main :nth-child(2) {
    width: 10%;
    object-fit: contain;
    margin-top: 200px;
  }
  .home-main {
    height: 1100px;
  }
  .home-flex {
    height: 860px !important;
  }
}
@media screen and (max-width: 900px) {
  .home-flex {
    height: 740px !important;
  }
}
@media screen and (max-width: 550px) {
  .home-flex {
    height: auto;
  }
}

.home-flex {
  display: flex;
  justify-content: center;
  width: 100% !important;
  height: 950px;
  position: absolute;
  z-index: -1;
  /* background-color: red; */
  background-image: linear-gradient(40deg, white 0%, rgb(232, 236, 239) 60%);
}

@media (max-width: 900px) {
  .home-main {
    height: 800px;
  }
  .home-main img {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .home-main {
    height: 800px;
  }
  .home-flex {
    height: 800px;
  }
}

/* preloader  */
.preloaderBg {
  position: fixed;
  z-index: 10000000000;
  top: 0;
  background: white;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #000; */
}

.preloader-wrap {
  position: relative;
  width: 250px;
  height: 250px;
}

.preloader-logo {
  margin: auto;
  background: url(../public/logo.png) no-repeat center;
  background-size: 200px;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 25px;
  left: 25px;
}

.preloader-circle {
  /*border: 5px solid #f3f3f3; GG*/
  border: 5px solid #f3f3f3;
  /*border-top: 5px solid #c18263; GG*/
  border-top: 5px solid #2f9fb7;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  animation: spin 1s ease-in-out infinite;
  position: absolute;
  top: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
