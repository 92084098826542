
 
.work-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 130px;
}
.work{
    width: 100%;
    max-width: 1200px;
    display: flex;
    height: 500px;
}
.left-work{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.right-work{
    width: 60%;
   
}
.left-work .work-title{
    font-weight: 400;
    font-size: 50px;
    font-family: "Unbounded", sans-serif;
    line-height: 55px;
}
.left-work .work-title strong{
    font-weight: 400;
    color: var(--main-color);
}
.left-work .work-text{
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: rgba(38, 38, 38, 1);
    width: 86%;
}
.left-work .work-button{
    font-family: "Unbounded", sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: 800;
    color: var(--main-color);
    width: 443px;
    height: 69px;
    border: .85px solid var(--main-color);
    box-shadow: 3px 3px 0 0 white, 3px 3px 0 .85px var(--main-color);
    background-color: white;
    display: inline-block;
    transition: color .5s;
    position: relative;
    --color: rgba(136, 60, 136, 1);
    overflow: hidden;
    z-index: 1;
}
.left-work .work-button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 69px;
    width: 160%;
    border-radius: 0%;
   
}

.left-work .work-button:hover {
    color: #fff;
}

.left-work .work-button:before {
    top: 0;
    left: 100%; /* Changed from 100% */
    transition: all .7s;
}

.left-work .work-button:hover:before {
    top: 0;
    left: -60%; /* Changed from -30px */
}

.left-work .work-button:active:before {
    background: var(--main-color);
    transition: background-color 0s;
}

.right-work{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: end;
    align-items: end;
}
.right-work img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width:1300px) {
    .work{
        padding: 0px 20px 0px 20px;
    }
    
}
@media screen and (max-width:950px) {
    .work{
        flex-direction: column;
        height: auto;
    }
    .left-work{
        width: 100%;
        align-items: center;
        text-align: center;
    }
    .left-work p{
        padding-bottom: 20px;
    }
    .right-work{
        padding-top: 50px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    .right-work img{
        width: 500px;
        height: 500px;
    }

    
}
@media screen and (max-width:500px) {
    .left-work .work-title{
        font-size: 20px;
        line-height: 30px;
    }
    .left-work .work-text{
        font-size: 18px;
    }
    .right-work img{
        width: 80%;
        height: 80%;
    }
    .left-work .work-button{
        width: 70%;
    }
    .work-main{
        margin-top: 50px;
    }
}
