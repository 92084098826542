.terms .appbar-main{
    position: sticky;
    top: 0;
    background-color: white;
}

.terms-main{
    width: 100%;
    display: flex;
    justify-content: center;
}
.terms{
    width: 100%;
    max-width: 1250px;

}
.terms h3{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    color: var(--main-color);
    font-family: "unbounded", sans-serif;
   font-weight: bold;
}

.terms h4{
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-top: 30px;
    margin-bottom: 80px;
    color: var(--main-color);
}
.terms p{
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
}
.terms ul li{
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
    
}
.terms ul li::marker {
    color: var(--main-color); 
}
.terms strong p{
    color: var(--main-color);
    font-weight: bold;
}
.terms strong{
    color: var(--main-color);
    font-weight: bold;
}
.terms a{
    color: var(--main-color);
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
}
.text-left{
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
}
.terms{
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 30px;
}