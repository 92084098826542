.clinic-main{
    width: 100%;
    display: flex;
    justify-content: center;
}
.clinic{
    width: 100%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.clinic-bar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100px;
}
.bar-buttonss :nth-child(1){
    background-color: white;
    font-size: 18px;
    color: black;
    border: none;
    margin-right: 20px;
}
.bar-buttonss :nth-child(2){
    background-color: var(--main-color);
    color: white;
    border: none;
    height: 50px;
    border-radius: 12px;
    font-size: 22px;
    padding:0 20px 0 20px;
}
.clinic-request-main{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--main-color);
    height: 200px;
    align-items: center;
}
.clinic-request{
    width: 100%;
    max-width: 1000px;
   
    display: flex;
    flex-direction: column;
    align-items: center;
}
.clinic-request :nth-child(1){
    font-size: 55px;
    color: white;
    font-weight: 600;
   padding-bottom: 10px;
}
.clinic-request :nth-child(2){
    font-size: 19px;
    color: white;
   
}
.clinic-fields{
    margin-top: 40px;
    width: 100%;
    max-width: 1250px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}
.main-clinic-fields{
    width: 100%;
    display: flex;
    justify-content: center;
}
.field1{
    display: flex;
    justify-content: space-between;
    width: 100% ;
    margin-bottom: 20px;
}
.field2{
    display: flex;
    justify-content: center;
    width: 100% ;
    margin-bottom: 20px;
}
.field2 input{
    height: 35px;
    width: 97%;
    border: 1px solid rgba(161, 161, 161, 0.438);
    border-radius: 6px;
    outline: none;
    font-size: 20px;
    
}
.fields{
    width: 100%;
}
.clinic-fields p{
    font-size: 20px;
    margin-bottom: 10px;
}
.field1 input{
    height: 35px;
    width: 95%;
    border: 1px solid rgba(161, 161, 161, 0.438);
    border-radius: 6px;
    outline: none;
    font-size: 20px;
}

.clinic-terms-main{
    width: 100%;
    display: flex;
    justify-content: center;
}
.clinic-terms{
    width: 100%;
    max-width: 1250px;
    margin: 30px;
    height: 360px;
    overflow-y: scroll;
    border: 1px solid var(--main-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 30px;
    box-sizing: border-box;
}
.clinic-terms .terms-head{
    font-size: 24px;
    color: var(--main-color);
    font-weight: 500;
}
.clinic-terms .terms-para{
    font-size: 19px;
    color: var(--main-color);
    width: 100%;
    text-align: start;
}
.terms-confirm-main{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.terms-confirm{
    width: 100%;
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 30px;
}
.terms-confirm :nth-child(1){
    color: var(--main-color);
    font-size: 16px;
    font-weight: bold;
}
.terms-check{
    display: flex;
    align-items: center;
    margin-top: 10px;
   
}
.terms-check p{
    font-size: 20px;
    color: var(--main-color);
    margin: 0;
    margin-left: 5px;
}
.ui-checkbox {
    --primary-color: #1677ff;
    --secondary-color: #fff;
    --primary-hover-color: #4096ff;
    /* checkbox */
    --checkbox-diameter: 20px;
    --checkbox-border-radius: 5px;
    --checkbox-border-color: #d9d9d9;
    --checkbox-border-width: 1px;
    --checkbox-border-style: solid;
    /* checkmark */
    --checkmark-size: 1.2;
  }
  
  .ui-checkbox, 
  .ui-checkbox *, 
  .ui-checkbox *::before, 
  .ui-checkbox *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .ui-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: var(--checkbox-diameter);
    height: var(--checkbox-diameter);
    border-radius: var(--checkbox-border-radius);
    background: var(--secondary-color);
    border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20px;
  }
  
  .ui-checkbox::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    border-radius: inherit;
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    
  }
  
  .ui-checkbox::before {
    top: 40%;
    left: 50%;
    content: "";
    position: absolute;
    width: 4px;
    height: 7px;
    border-right: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
    -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
  }
  
  /* actions */
  
  .ui-checkbox:hover {
    border-color: var(--primary-color);
  }
  
  .ui-checkbox:checked {
    background: var(--primary-color);
    border-color: transparent;
  }
  
  .ui-checkbox:checked::before {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
  
  .ui-checkbox:active:not(:checked)::after {
    -webkit-transition: none;
    -o-transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: none;
    opacity: 1;
  }
  .robot{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .robot button{
    width: 126px;
    height: 50px;
    background-color: var(--main-color);
    border: none;
    color: white;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 20px;
  }
  .robot button:hover{
    background-color: var(--main-color);
    color: white;

  }
