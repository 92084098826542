

.footer-main{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--main-color);
}
.footer{
    width: 100%;
    max-width: 1250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
}

.footer-links{
    display: flex;
    color: white;
    font-family: "Plus Jakarta Sans", sans-serif;
   font-size: 14px;
   font-weight: 500;
   line-height: 22px;
  
}
.footer-links2{
    color: white;
    font-family: "Plus Jakarta Sans", sans-serif;
   font-size: 14px;
   font-weight: 500;
   line-height: 22px;
   width: 37%;
}
.footer-links2 button{
    padding: 10px;
    border: none;
    background-color: white;
    border-radius: 5px;
    color: var(--main-color);
    font-family: "Plus Jakarta Sans", sans-serif;
   padding-left: 15px;
   padding-right: 15px;
   font-weight: bold;
   box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.548);
}
.footer-links2 p{
    margin-bottom: 0;
    word-spacing: 2px;
}
.footer-links1{
    display: flex;
    color: white;
    font-family: "Plus Jakarta Sans", sans-serif;
   font-size: 14px;
   font-weight:500 ;
   line-height: 22px;
}
.footer-links p{
    margin-bottom: 0 !important;
}
.footer-links1 p{
    padding-right: 10px;
    margin-bottom: 0 !important;
}
.footer-links img{
    padding: 0px 10px 0px 10px;
}
@media screen and (max-width:1300px) {
    .footer{
        padding: 0 20px 0 20px;
    }
}
@media screen and (max-width:950px) {
    .footer{
        flex-direction: column;
        align-items: start;
        height: 200px;
        justify-content: space-evenly;

    }
    .footer-links{
        width: auto;
    }
}