@import url('https://fonts.googleapis.com/css2?family=Unbounded&display=swap');

.appbar-main{
    width: 100%;
    display: flex;
    justify-content: center;
  
    /* background-color: white; */
}
.appbar{
    width: 100%;
    max-width: 1250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    
}
.appbar img{
    width: 250px;
   
}

.logo img{
     object-fit: contain;
}
.appbar-button{
    width: 100%;
    display: flex;
    justify-content: end;
}
.appbar-button button {
    font-family: "Unbounded", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 800;
    color: var(--main-color);
    width: 326px;
    height: 50px;
    border: .85px solid var(--main-color);
    box-shadow: 3px 3px 0 0 white, 3px 3px 0 .85px black;
    background-color: white;
    display: inline-block;
    transition: color .5s;
    position: relative;
    --color: rgba(136, 60, 136, 1);
    overflow: hidden;
    z-index: 1;
}

.appbar-button button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 60px;
    width: 160%;
    border-radius: 0%;
   
}

.appbar-button button:hover {
    color: #fff;
}

.appbar-button button:before {
    top: 0;
    left: 100%; /* Changed from 100% */
    transition: all .7s;
}

.appbar-button button:hover:before {
    top: 0;
    left: -60%; /* Changed from -30px */
}

.appbar-button button:active:before {
    background: var(--main-color);
    transition: background-color 0s;
}


@media screen and (max-width: 1300px) {
    .appbar {
       padding-left: 30px;
       padding-right: 30px;
       
    }
}
@media screen and (max-width: 700px) {
     .appbar-button button{
        width: 200px;
        font-size: 10px;
     }
     .logo img{
        width: 150px;
     }
    
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
}

.dropdown-menu button {
    display: block;
    width: 100%;
    text-align: left;
}
@media screen and (max-width:990px) {
    .appbar-button button {
        border: none;
        box-shadow: none;
        width: 100%;
        background-color: white;
        border-bottom: 1px solid var(--main-color);
        
       
    }
    
}

