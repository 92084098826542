@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


.chose-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 90px;
}
.chose{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}
.chose-text{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom:50px ;
}
.chose-text1{
    font-size: 45px;
    font-weight: 400;
    font-family: "Unbounded", sans-serif;
    line-height: 55px;
    text-align: center;
}
.chose-text1 strong{
    color: var(--main-color);
    font-weight: 400;
}
.chose-text2{
    font-family: "Unbounded", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.chose-cards{
    display: flex;
    justify-content: space-evenly;
}
.chose-card1{
    background-color: rgba(242, 243, 245, 1);
    width: 355px;
    height: 446px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
    border-radius: 4px;
}
.chose-card2{
    width: 426px;
    height: 446px;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 12px;
}
.card-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.card-list :nth-child(2){
    width: 80%;
}
.card-list :nth-child(1){
    color: rgba(25, 29, 35, 1);
    font-weight: bolder;
}
.card-content{
    height: 70%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.card-list p{
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(100, 116, 139, 1);
}
.card1-head p{
    font-family: "Manrope", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color:rgba(25, 29, 35, 1) ;
}
.card2-head p{
    font-family: "Manrope", sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    color: white;
}
.card2-button button{
    width: 378px;
    height: 44px;
    background-color: rgba(255, 255, 255, 1);
    color: black;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    
}
.card2-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.card2-list p{
    margin-bottom: 0;
}
.card2-list :nth-child(2){
    width: 80%;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: white;
}
.card2-content{
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
}
@media screen and (max-width:1100px) {
    .chose-card2{
        width: 350px;
    }
    .chose-card1{
        width: 320px;
    }
    .card2-button{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .card2-button button{
        width: 80%;
    }
    .chose-card2 .card2-head{

    }
    
}
@media screen and (max-width:950px) {
    .chose-cards{
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    .chose-card1{
        margin-bottom: 30px;
    }
    .chose-card2{
        margin-bottom: 30px;
    }
    .chose-text1{
        font-size: 35px;
        padding: 0 20px 0 20px;
    }
    .chose-text2{
        font-size: 20px;
        padding: 0 20px 0 20px;
    }
}
@media screen and (max-width:500px) {
    .chose-text1{
        font-size: 20px;
        line-height: 30px;
    }
    .chose-text2{
        font-size: 18px;
    }
}
@media screen and (max-width:1100px) {
    .chose-card2{
        height: 550px;
    }
    
}
