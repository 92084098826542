@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital@0;1&family=Unbounded:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,500;1,500&family=Unbounded:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,700;1,700&family=Plus+Jakarta+Sans:ital,wght@0,500;1,500&family=Unbounded:wght@500&display=swap');
.find-main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    height: 587px;
}
.find{
    width: 100%;
    max-width: 1250px;
    display: flex;
    justify-content: space-between;
   
}
.find-left{
    width: 39%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.find-left .find-title{
    font-size: 45px;
    font-weight: 400;
    font-family: "Unbounded", sans-serif;
    line-height: 55px;
}
.find-left .find-title strong{
    color: var(--main-color);
    font-weight: 400;
}
.find-left .find-text{
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    font-family: "Plus Jakarta Sans", sans-serif;
}
.find-left .find-button{
    font-family: "Unbounded", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 800;
    color: var(--main-color);
    width: 325px;
    height: 50px;
    border: .85px solid var(--main-color);
    box-shadow: 3px 3px 0 0 white, 3px 3px 0 .85px var(--main-color);
    background-color: white;
   
    margin-bottom: 30px;
    display: inline-block;
    transition: color .5s;
    position: relative;
    --color: rgba(136, 60, 136, 1);
    overflow: hidden;
    z-index: 1;
}
.find-left .find-button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 60px;
    width: 160%;
    border-radius: 0%;
   
}

.find-left .find-button:hover {
    color: #fff;
}

.find-left .find-button:before {
    top: 0;
    left: 100%; /* Changed from 100% */
    transition: all .7s;
}

.find-left .find-button:hover:before {
    top: 0;
    left: -60%; /* Changed from -30px */
}

.find-left .find-button:active:before {
    background: var(--main-color);
    transition: background-color 0s;
}
.find-right{
    width: 58%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
}
.find-right .left-img{
    width: 49%;
    height: 100%;
   
}
.find-right .left-img img{
    object-fit: cover;
    width: 100%;
    height: 560px;
    border-radius: 12px;
}
.find-right .right-right{
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.find-right .right-right .right-img{
    width: 100%;
    height: 55%;
}
.find-right .right-right .right-img img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}
.find-right .right-right .right-cont{
    width: 90%;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}
.find-right .right-right .right-cont .p1{
    font-family: "Plus Jakarta Sans", sans-serif;
   font-weight: 600;
   font-size: 63px;
   line-height: 78px;
   color: rgba(49, 31, 25, 1);
}
.find-right .right-right .right-cont .p1 strong{
    font-weight: 100;
   color: rgba(49, 31, 25, 1);
   font-family: "Playfair Display", serif;
    line-height: 20px;
   font-size: 41px;
   
}
.find-right .right-right .right-cont .p2{
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(95, 77, 66, 1);
    width: 80%;
    
}
.image-container {
    position: relative;
    width: fit-content;
}

.overlay-button {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; 
    margin: 20px;
    width: 225px;
    height: 38px;
    border-radius: 50px;
    border: none;
    background-color: white;
    color: var(--main-color);
    font-family: "Plus Jakarta Sans", sans-serif;
   font-size: 14px;
   font-weight: 400;
   text-align: center;
}


  .pp{
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: center;
  }
  
  .profile {
    width: 45px;
    height: 45px; 
    border-radius: 50%;
    background-color: transparent; /* Default background color */
    position: relative;
    margin-left: -12px; 
    background-size: contain;
    border: 2px solid rgba(255, 237, 213, 1) ;
}
.find-images{
    padding-left: 10px;
}
@media screen and (max-width:1300px) {
    .find-left {
        padding-left: 30px;
    }
    .find-right{
        padding-right: 30px;
    }  
}
@media screen  and (max-width:1200px) {
     .find-left .find-title{
        font-size: 40px;
     }
     .find-left .find-text{
        font-size: 30px;
     }
     .profile{
        width: 40px;
        height: 40px;
     }
     .find-right .right-right .right-cont .p1{
       font-size: 55px;
    }
}
@media screen  and (max-width:1100px) {
    .find-left .find-title{
       font-size: 35px;
    }
    .find-left .find-text{
       font-size: 25px;
    }
    .profile{
       width: 35px;
       height: 35px;
    }
    .find-right .right-right .right-cont .p1{
      font-size: 50px;
   }
}
 @media screen and (max-width:900px) {
    .find{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .find-main{
        height: auto;
    }
    .find-left{
        width: 90%;
        align-items: center;
        text-align: center;
    }
    .find-left p{
        padding-bottom: 20px;
    }
    .find-right{
        width: 100%;
        margin-bottom: 60px;
    }
    
 }
 @media screen and (max-width:500px) {
     .find-main{
        margin-top: 10px;
     }
     .find-right .right-right .right-cont .p1{
       font-size: 25px;
       width: 90%;
       line-height: 30px;
    }
     
    .find-right .right-right .right-cont .p2{
        font-size: 13px;
        width: 90%;
     }
    .profile{
        width: 25px;
        height: 25px;
    }
    .find-left{
       align-items: center;
       justify-content: center;
       padding: 0;
    }
    .find-right{
        align-items: center;
        width: 100%;
       
    }
    .find{
        overflow: hidden;
    }
    .find-left .find-title{
        font-size: 18px;
        line-height: 30px;
        
        width: 100%;
    }
    .find-left .find-text{
        font-size: 17px;
    }
    .pp{
        height: auto;
    }
  .find-right .right-right .right-cont .p1 strong{
     font-size: 25px;

  }

 }
 @media screen and (max-width:600px) {
    .find-main{
       margin-top: 60px;
    }
    .overlay-button{
        width: 85%;
        font-size: 10px;
    }
    .find-right .right-right .right-cont .p1{
      font-size: 25px;
      width: 90%;
      line-height: 30px;
   }
    
   .find-right .right-right .right-cont .p2{
       font-size: 13px;
       width: 90%;
    }
   .profile{
       width: 25px;
       height: 25px;
   }
   .find-left{
      align-items: center;
      justify-content: center;
      padding: 0;
   }
   .find-right{
       align-items: center;
       width: 100%;
      
   }
   .find{
       overflow: hidden;
   }
   .find-left .find-title{
       font-size: 18px;
       line-height: 30px;
       
       width: 100%;
   }
   .find-left .find-text{
       font-size: 17px;
   }
   .pp{
       height: auto;
   }
 .find-right .right-right .right-cont .p1 strong{
    font-size: 25px;

 }

}